<template>
  <div class="dispatch-statistics" ref="dispatchStatistics">
    <div class="form-area" ref="form">
      <analysis-form
        @getFormVal="getFormVal"
        :showMonth="form.type != 3"
        :showCompany="form.type == 1"
        :showCar="form.type != 1"
        :showCommand="form.type === 3"
      >
        <el-button
          type="primary"
          size="small"
          v-if="form.type === 2"
          :disabled="carArr.length === 0"
          :loading="downLoadStatus"
          @click="exportData(2)"
          >导出</el-button
        >
        <el-button
          type="primary"
          size="small"
          v-if="form.type === 3"
          :disabled="carDetialsArr.length === 0"
          :loading="downLoadStatus"
          @click="exportData(3)"
          >导出</el-button
        >
      </analysis-form>
      <el-radio-group v-model="form.type" @change="changeState">
        <el-radio :label="v.label" v-for="(v, i) in options" :key="i">{{
          v.value
        }}</el-radio>
      </el-radio-group>
    </div>
    <!-- 企业统计 -->
    <div class="form-table" v-if="form.type === 1 && companyArr.length > 0">
      <el-table :data="companyArr" :height="tableHeight" style="width: 100%">
        <el-table-column type="index" width="70" label="序号"></el-table-column>
        <el-table-column
          prop="companyName"
          label="所属企业"
          :show-overflow-tooltip="true"
          width="200px"
        ></el-table-column>
        <el-table-column
          v-for="(obj, key) in companyArr[0].list"
          :key="key"
          width="120px"
          :show-overflow-tooltip="true"
          :label="Object.keys(obj)[0]"
        >
          <template>{{ obj[Object.keys(obj)[0]] }}</template>
        </el-table-column>
        <el-table-column prop="total" label="总计"></el-table-column>
      </el-table>
    </div>

    <!--车辆统计 -->
    <div class="form-table" v-if="form.type === 2 && carArr.length > 0">
      <el-table :data="carArr" :height="tableHeight" style="width: 100%">
        <el-table-column type="index" width="70" label="序号"></el-table-column>
        <el-table-column
          prop="cph"
          label="车牌号"
          :width="100 * $store.state.vehicle.screenCoefficient"
          :show-overflow-tooltip="true"
        ></el-table-column>
        <el-table-column
          label="车牌颜色"
          prop="licensePlateColorStr"
          :width="80 * $store.state.vehicle.screenCoefficient"
          :show-overflow-tooltip="true"
        ></el-table-column>
        <el-table-column
          prop="vehicleTypeStr"
          label="车辆类型"
          :formatter="formatNull"
          :width="130 * $store.state.vehicle.screenCoefficient"
          :show-overflow-tooltip="true"
        ></el-table-column>
        <el-table-column
          prop="companyName"
          label="所属企业"
          :show-overflow-tooltip="true"
          width="200px"
        ></el-table-column>
        <el-table-column
          v-for="(obj, key, index) in carArr[0].map"
          :key="index"
          width="120px"
          :show-overflow-tooltip="true"
          :label="key"
        >
          <template slot-scope="scope">{{
            carArr[scope.$index].map[key]
          }}</template>
          <!-- <template>{{ obj }}</template> -->
        </el-table-column>
        <el-table-column prop="total" label="总计"></el-table-column>
      </el-table>
      <div class="pagination">
        <el-pagination
          background
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
          :page-size="form.pageSize"
          :current-page="form.currentPage"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
        ></el-pagination>
      </div>
    </div>
    <!-- 车辆详情 -->
    <div v-if="form.type === 3" class="form-table">
      <el-table :data="carDetialsArr" style="width: 100%" :height="tableHeight">
        <el-table-column type="index" label="序号" width="50"></el-table-column>
        <el-table-column
          prop="cph"
          label="车牌号"
          :width="100 * $store.state.vehicle.screenCoefficient"
          :show-overflow-tooltip="true"
        ></el-table-column>
        <el-table-column
          label="车牌颜色"
          prop="licensePlateColorStr"
          :width="80 * $store.state.vehicle.screenCoefficient"
          :show-overflow-tooltip="true"
        ></el-table-column>
        <el-table-column
          prop="vehicleTypeStr"
          label="车辆类型"
          :formatter="formatNull"
          :width="130 * $store.state.vehicle.screenCoefficient"
          :show-overflow-tooltip="true"
        ></el-table-column>
        <el-table-column
          prop="companyName"
          label="所属道路运输企业"
          :width="200 * this.$store.state.vehicle.screenCoefficient"
          :show-overflow-tooltip="true"
        ></el-table-column>
        <el-table-column
          prop="commandDetail"
          label="内容"
          :formatter="formatNull"
          :width="350 * $store.state.vehicle.screenCoefficient"
          :show-overflow-tooltip="true"
        ></el-table-column>
        <el-table-column
          prop="sendTime"
          label="发送时间"
          :formatter="formatNull"
        ></el-table-column>
        <el-table-column prop="userName" label="发送人员"></el-table-column>
      </el-table>
      <div class="pagination">
        <el-pagination
          background
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
          :page-size="form.pageSize"
          :current-page="form.currentPage"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
        ></el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import analysisForm from "./components/form.vue";

import {
  scheduleVehicleDetail,
  scheduleVehicleCount,
  scheduleCompanyCount,
} from "@/api/lib/gps-api.js";

import { getCurent, formatDate } from "@/common/utils/index";
import { addExcelExport } from "@/api/lib/refreshAlarm.js";

export default {
  name: "dispatchStatistics",
  components: {
    analysisForm,
  },
  data () {
    return {
      tableHeight: 0,
      total: 0,

      options: [
        { value: "企业统计表", label: 1, queryPath: "" },
        { value: "车辆统计表", label: 2, queryPath: "/schedule/exportVehicleCount" },
        { value: "车辆明细表", label: 3, queryPath: "/schedule/exportVehicleDetail" },
      ],

      form: {
        vehicleNos: [],
        companyId: null,
        beginTime: null,
        endTime: null,
        currentPage: 1,
        pageSize: 10,
        type: 1,
        month: null,
      },
      exportForm: {}, //导出需要的数据
      downLoadStatus: false, //导出
      //企业
      companyArr: [],
      //车辆统计
      carArr: [],
      carDetialsArr: [],
      typeObj: null,
      modelObj: null,
    };
  },
  methods: {
    // 计算表格高度
    computeHeight () {
      const wholeHeight = this.$refs.dispatchStatistics.clientHeight;
      const formHeight = this.$refs.form.clientHeight;
      this.tableHeight = wholeHeight - formHeight - 30 - 10 - 24;
    },
    //导出
    exportData (key) {
      this.downLoadStatus = true;
      delete this.exportForm.currentPage;
      delete this.exportForm.pageSize;
      let item = this.options.find((res) => res.label === key);

      let data = {
        baseUrl: "report",
        fileName: `车辆调度${item.value}-${getCurent()}`,
        queryParam: JSON.stringify(this.exportForm),
        queryPath: item.queryPath,
      };
      addExcelExport(data)
        .then((res) => {
          this.$message.success(res.msg);
          this.downLoadStatus = false;
          this.$store.dispatch("setRefreshStatus", true);
        })
        .catch(() => {
          this.downLoadStatus = false;
        });
    },
    /**请求类型切换 */
    changeState () {
      this.reset();
    },
    /**请求参数清空*/
    reset () {
      this.form.currentPage = 1;
      this.form.pageSize = 10;

      this.total = 0;
      this.companyArr = [];
      this.carArr = [];
      this.carDetialsArr = [];
    },
    /**点击查询 */
    getFormVal (v) {
      this.reset();
      this.form = { ...this.form, ...v };

      this.onSubmit();
    },
    /**查询 */
    onSubmit () {
      let data = { ...this.form };

      switch (this.form.type) {
        case 1:
          /**企业统计表 */
          delete data.vehicleNos;
          delete data.beginTime;
          delete data.endTime;
          delete data.type;
          this.getcompanyArr(data);
          break;
        case 2:
          /**车辆统计表 */
          delete data.companyId;
          delete data.beginTime;
          delete data.endTime;
          delete data.type;
          this.getCarArr(data);
          break;
        case 3:
          /**车辆明细表 */
          delete data.companyId;
          delete data.month;
          delete data.type;
          this.getCarDetialsArr(data);

          break;

        default:
          break;
      }
      this.exportForm = { ...data };
    },

    /**企业统计表 */
    getcompanyArr (data) {
      let self = this;
      scheduleCompanyCount(data).then((res) => {
        if (res) {
          self.companyArr = [res.data];
        }
      });
    },
    /**车辆统计表 */
    getCarArr (data) {
      let self = this;
      scheduleVehicleCount(data).then((res) => {
        if (res) {
          self.carArr = res.data.list;
          self.total = res.data.total;
        }
      });
    },
    /**车辆明细表 */
    getCarDetialsArr (data) {
      let self = this;
      scheduleVehicleDetail(data).then((res) => {
        if (res.code === 1000) {
          self.carDetialsArr = res.data.list || [];
          self.total = res.data.total;
        } else {
          self.carDetialsArr = [];
          self.total = 0;
        }
      });
    },

    /**数据为空默认显示- */
    formatNull (row, column, cellValue) {
      return cellValue == null || cellValue == "" ? "-" : cellValue;
    },

    /** 分页参数切换*/
    handleSizeChange (val) {
      this.form.pageSize = val;
      this.form.currentPage = 1;
      if (this.carArr.length === 0 && this.carDetialsArr.length === 0) return;
      this.onSubmit();
    },
    /**分页参数切换 */
    handleCurrentChange (val) {
      this.form.currentPage = val;
      this.onSubmit();
    },
  },
  created () { },
  mounted () {
    this.$nextTick(() => {
      this.computeHeight();
      window.addEventListener("resize", this.computeHeight, false);
    });
  },
  destroyed () {
    window.removeEventListener("resize", this.computeHeight);
  },
};
</script>

<style lang="scss" scoped>
.dispatch-statistics {
  height: 100%;
  width: 100%;
  border-radius: 4px;
  padding: 16px 20px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
.theme-project-gps {
  .dispatch-statistics {
    background: #ffffff;
    box-shadow: 0 0 4px 0 rgba($color: #343434, $alpha: 0.1);
  }
}
</style>
